import React from 'react';
import { PageBlock } from '@design-system/page-block';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import { DangerouslySetInnerHtml } from 'layout';

import { LdsProps } from './models';

const LdsBlock = ({
  ldsContent,
  ldsVersionId,
  ldsBusinessId,
  pageBlockUI,
  ot,
  platform,
}: LdsProps) => {
  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: ot?.base_url,
    businessId: ldsBusinessId,
    versionId: ldsVersionId,
    platform,
  });

  return (
    <PageBlock {...pageBlockUI} className="lds-block">
      {!isCheckingLdsVersion && <DangerouslySetInnerHtml html={updatedLdsContent ?? ldsContent} />}
    </PageBlock>
  );
};

export default LdsBlock;
